import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import ToolTip from 'theme/ui/tool-tip'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { routes } from '../..'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ workflow, last_updated, isActionActive }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const ID = workflow?.id

	return (
		<div className='w-[180px]'>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={ID} />
			{isActionActive &&
			(isAllowTo(permissions?.workflowTemplate?.edit) ||
				isAllowTo(permissions?.workflowTemplate?.assign) ||
				isAllowTo(permissions?.workflowTemplate?.delete)) ? (
				<div className='flex space-x-2'>
					{isAllowTo(permissions?.workflowTemplate?.assign) ? (
						<Fragment>
							<ToolTip
								targetId={`workflow-assign-tooltip-${ID}`}
								tooltipText='Assign'
								placement='top'
							/>
							<Link
								id={`workflow-assign-tooltip-${ID}`}
								to={routes.assign(ID)}
								className='action-btn btn-primary-outline'>
								<i className='uil-clipboard-notes' style={{ fontSize: 17 }} />
								<span>Assign</span>
							</Link>
						</Fragment>
					) : undefined}

					{isAllowTo(permissions?.workflowTemplate?.edit) ? (
						<Fragment>
							<ToolTip
								targetId={`workflow-edit-tooltip-${ID}`}
								tooltipText='Edit'
								placement='top'
							/>
							<Link
								id={`workflow-edit-tooltip-${ID}`}
								to={routes.edit(ID)}
								className='action-btn btn-primary-outline'>
								<i className='uil-pen' style={{ fontSize: 15 }} />
							</Link>
						</Fragment>
					) : undefined}

					{isAllowTo(permissions?.workflowTemplate?.delete) ? (
						<Fragment>
							<ToolTip
								targetId={`workflow-delete-tooltip-${ID}`}
								tooltipText='Delete'
								placement='top'
							/>
							<button
								id={`workflow-delete-tooltip-${ID}`}
								type='button'
								className='action-btn btn-red-outline'
								onClick={() => setIsOpen(true)}>
								<i className='uil-trash-alt' style={{ fontSize: 16 }} />
							</button>
						</Fragment>
					) : undefined}
				</div>
			) : (
				<span className=' text-sm  !text-gray-700'>{last_updated}</span>
			)}
		</div>
	)
}

export default Actions
