import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const TagsDropdown = ({
	tagSearch,
	setTagSearch,
	tagList,
	selectedTags,
	handleTagChange,
	applyTags,
	selectedRecords,
	createTag,
	previousData,
}) => {
	const [isTagOpen, setIsTagOpen] = useState(false)
	const [filteredTags, setFilteredTags] = useState(tagList)

	const toggleTagSection = () => {
		setIsTagOpen(!isTagOpen)
	}
	const handleTagSearch = (e) => {
		setTagSearch(e.target.value)
	}

	useEffect(() => {
		if (tagSearch) {
			setFilteredTags(tagList.filter((tag) => tag.includes(tagSearch)))
		} else {
			setFilteredTags(tagList)
		}
	}, [tagSearch])

	function IndeterminateCheckbox({ checked, indeterminate, tag, ...rest }) {
		const ref = React.useRef(null)

		useEffect(() => {
			if (ref.current) {
				ref.current.indeterminate = indeterminate
			}
		}, [indeterminate])

		return (
			<input
				type='checkbox'
				className='form-check-input m-0'
				ref={ref}
				checked={checked}
				{...rest}
				onChange={(e) => handleTagChange(e, tag)}
			/>
		)
	}

	useEffect(() => {
		if (tagList) {
			setFilteredTags(tagList)
		}
	}, [tagList])
	const [tagsChanged, setTagsChanged] = useState(false)
	useEffect(() => {
		const hasTagsChanged = selectedRecords.some((item, index) => {
			const prevTags = previousData[index]?.tags || []
			const currentTags = item.tags || []
			return (
				prevTags.length !== currentTags.length ||
				prevTags.some((tag) => !currentTags.includes(tag)) ||
				currentTags.some((tag) => !prevTags.includes(tag))
			)
		})

		if (hasTagsChanged) {
			setTagsChanged(true)
		} else {
			setTagsChanged(false)
		}
	}, [selectedRecords])

	return (
		<>
			<Dropdown isOpen={isTagOpen} className='' toggle={() => toggleTagSection()}>
				<button
					type='button'
					className={classNames('btn btn-secondary', {
						'!bg-main/20 !text-main !shadow-md': isTagOpen,
					})}>
					<DropdownToggle
						tag='button'
						type='button'
						className='m-0 !border !border-none bg-transparent p-0'>
						<span>Groups</span>
					</DropdownToggle>
				</button>

				<DropdownMenu className='dropdown-menu-md dropdown-menu-start mt-2 p-0 pt-2 shadow-md'>
					<div className='rounded '>
						<div className='mt-2 px-3'>
							<input
								className='form-control'
								type='text'
								value={tagSearch}
								onChange={handleTagSearch}
								placeholder={
									tagList?.length == 0 ? 'Enter Group name' : 'Search groups...'
								}
							/>
						</div>
						<div className='custom-scroll mb-2 mt-3 flex max-h-[154px] flex-col overflow-y-auto'>
							{filteredTags?.map((tag) => (
								<label
									key={tag}
									className='flex items-center gap-2 p-1 px-3  hover:bg-gray-200'>
									<IndeterminateCheckbox
										checked={selectedTags[tag]?.checked}
										indeterminate={selectedTags[tag]?.indeterminate}
										tag={tag}
									/>
									<span className='line-clamp-1 cursor-pointer '>{tag}</span>
								</label>
							))}
						</div>
					</div>
					{tagSearch || (selectedRecords?.length > 0 && tagsChanged) ? (
						<div className='flex flex-col !border !border-t-gray-600 pb-3 pt-2'>
							{tagSearch && (
								<p
									className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
										tagSearch
											? 'cursor-pointer  font-medium text-black'
											: ' font-normal text-gray-600  '
									} `}
									onClick={() => createTag(tagSearch)}>
									Create New
								</p>
							)}

							{selectedRecords?.length > 0 && tagsChanged && (
								<p
									onClick={applyTags}
									className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
										selectedRecords?.length > 0
											? 'cursor-pointer  font-medium text-black'
											: ' font-normal text-gray-600  '
									} `}>
									Apply
								</p>
							)}
						</div>
					) : (
						<></>
					)}
				</DropdownMenu>
			</Dropdown>
		</>
	)
}

export default TagsDropdown
