function formatPerm(value) {
	return value
}

export const permissions = {
	note: {
		list: formatPerm('note:list', 'List notes'),
		create: formatPerm('note:create', 'Create notes'),
		edit: formatPerm('note:edit', 'Edit notes'),
	},
	client: {
		list: formatPerm('client:list', 'List clients'),
		view: formatPerm('client:view', 'View clients'),
		delete: formatPerm('client:delete', 'Delete clients'),
		invitedList: formatPerm('client:invitedList', 'List invited clients'),
		create: formatPerm('client:create', 'Invite clients'),
		import: formatPerm('client:import', 'Import clients'),
		verify: formatPerm('client:verify', 'Verify clients'),
		editInvited: formatPerm('client:editInvited', 'Edit Invited client'),
	},
	groups: {
		list: formatPerm('groups:list', 'List groups'),
		delete: formatPerm('groups:delete', 'Delete groups'),
		create: formatPerm('groups:create', 'Create groups'),
		edit: formatPerm('groups:edit', 'Edit groups'),
	},
	documentTemplate: {
		list: formatPerm('documentTemplate:list', 'List document templates'),
		create: formatPerm('documentTemplate:create', 'Create document templates'),
		edit: formatPerm('documentTemplate:edit', 'Edit document templates'),
		delete: formatPerm('documentTemplate:delete', 'Delete document templates'),
		deletedList: formatPerm('documentTemplate:deletedList', 'List Deleted document templates'),
		restore: formatPerm('documentTemplate:restore', 'Restore document templates'),
	},
	documentPackage: {
		list: formatPerm('documentPackage:list', 'List document packages'),
		create: formatPerm('documentPackage:create', 'Create document packages'),
		edit: formatPerm('documentPackage:edit', 'Edit document packages'),
		delete: formatPerm('documentPackage:delete', 'Delete document packages'),
	},
	completedDocument: {
		list: formatPerm('completedDocument:list', 'List completed documents'),
		view: formatPerm('completedDocument:view', 'View completed documents'),
		download: formatPerm('completedDocument:download', 'Download completed documents'),
	},
	workflowTemplate: {
		list: formatPerm('workflowTemplate:list', 'List workflow templates'),
		create: formatPerm('workflowTemplate:create', 'Create workflow templates'),
		edit: formatPerm('workflowTemplate:edit', 'Edit workflow templates'),
		assign: formatPerm('workflowTemplate:assign', 'Assign workflow templates'),
		delete: formatPerm('workflowTemplate:delete', 'Delete workflow templates'),
	},
	workflowOutstandings: {
		list: formatPerm('workflowOutstandings:list', 'List outstanding workflows'),
		view: formatPerm('workflowOutstandings:view', 'Review outstanding workflows'),
	},
	workflowFinalReview: {
		list: formatPerm('workflowFinalReview:list', 'List workflows for final review'),
		view: formatPerm('workflowFinalReview:view', 'View workflows for final review'),
	},
	assignedWorkflow: {
		list: formatPerm('assignedWorkflow:list', 'List assigned workflows'),
		fill: formatPerm('assignedWorkflow:fill', 'Fill assigned workflows'),
	},
	task: {
		list: formatPerm('task:list', 'List tasks'),
		create: formatPerm('task:create', 'Create tasks'),
		edit: formatPerm('task:edit', 'Edit tasks'),
	},
	teamMember: {
		list: formatPerm('teamMember:list', 'List team members'),
		inviteList: formatPerm('teamMember:inviteList', 'List invites'),
		invite: formatPerm('teamMember:invite', 'Invite team members'),
	},
	profileDetails: {
		edit: formatPerm('profileDetails:edit', 'Edit profile'),
		list: formatPerm('profileDetails:list', 'Profile details'),
	},
	workflowFiles: {
		list: formatPerm('workflowFiles:list', 'List workflow'),
		download: formatPerm('workflowFiles:download', 'Download workflow'),
	},
	mySubscription: {
		list: formatPerm('mySubscription:list', 'List subscriptions'),
		view: formatPerm('mySubscription:view', 'View subscription'),
	},
}
