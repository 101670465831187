import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'

import { useOnboardClientsMutation } from 'modules/clients/invited-client-api'
import Alert from 'theme/ui/alert'
import { Button, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { assignValidationSchema, getWorkflowAssignPayload, validateWorkflow } from '.'
import WorkflowQuery from '../workflow-query'
import AdvisorDropdown from './advisor-dropdown'
import CoApplicantField from './co-applicant-field'
import FinalReviewerDropdown from './final-reviewer-dropdown'
import ReceiverEmailDropdown from './receiver-email/receiver-email-dropdown'
import ReviewerDropdown from './reviewer-dropdown'
import WorkflowDropdown from './workflow-dropdown'

const ClientWorkflowAssignForm = ({ initialValues, onClose, selectedClients }) => {
	const [onboardClient] = useOnboardClientsMutation()

	const formik = useFormik({
		initialValues: {
			workflow: initialValues?.workflow || null,
			advisor: null,
			client: { user: null, screen_type: 'same_page' },
			co_applicants: [],
			reviewers: [],
			receiver_emails: [],
			final_reviewer: null,
			userCount: 1,
			isClientOnbording: true,
		},
		enableReinitialize: true,
		validationSchema: assignValidationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const workflowPayload = getWorkflowAssignPayload(values)
				await validateWorkflow(workflowPayload?.workflowTemplate?.id)
				const payload = {
					clients: selectedClients,
					isEmailSend: true,
					workflow: workflowPayload,
				}
				const response = await onboardClient(payload)

				if (response?.data?.status === 200) {
					onClose()
					notification('info', response?.data?.message)
				}

				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const { user_count, steps } = WorkflowQuery(formValues.workflow?.value)

	useEffect(() => {
		if (user_count > 1) {
			formik.setFieldValue('userCount', user_count)
			formik.setFieldValue(
				'co_applicants',
				Array(user_count - 1).fill({ user: null, screen_type: 'same_page' }),
			)
		} else {
			formik.setFieldValue('userCount', 1)
			formik.setFieldValue('co_applicants', [])
		}
	}, [user_count])

	const documents = useMemo(() => steps?.documents?.raw_documents || [], [steps])

	const document_packages = useMemo(() => steps?.documents?.document_packages || [], [steps])
	const upload_files = useMemo(() => {
		const files = steps?.uploads?.note || '[]'
		return JSON.parse(files)
	}, [steps])

	const advisor_reviewer = useMemo(() => {
		const reviewers = formValues?.reviewers.map((reviewer) => reviewer?.value)
		return [formValues?.advisor?.value, formValues?.final_reviewer?.value, ...reviewers].filter(
			(user) => typeof user !== 'undefined',
		)
	}, [formValues])

	const applicants = Array.isArray(formValues?.co_applicants)
		? formValues?.co_applicants.map((applicant) => applicant?.value)
		: []
	const client_applicants = [formValues?.client?.value, ...applicants].filter(
		(user) => typeof user !== 'undefined',
	)

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div>
					<div className='grid grid-cols-2 gap-6'>
						<div className='grid grid-cols-1 gap-y-3'>
							<div>
								<WorkflowDropdown />
								<Alert className='mt-2' variant='warning'>
									<div className='flex items-start space-x-1'>
										<i className='bx bxs-info-circle mr-1 text-base' />
										<p className='m-0 text-[13px]'>
											If you switch the workflow the rest of fields will be
											updated according to the requirement of the workflow.
										</p>
									</div>
								</Alert>
							</div>

							<AdvisorDropdown formValues={formValues} />
						</div>

						{formValues.userCount > 1 && Array.isArray(formValues.co_applicants) ? (
							<div>
								<FieldArray
									name='co_applicants'
									render={(arrayHelpers) => {
										const isDisabled =
											applicants.length === formValues.userCount - 1
										return (
											<CoApplicantField
												applicants={applicants}
												arrayHelpers={arrayHelpers}
												isDisabled={isDisabled}
												client_applicants={client_applicants}
												formValues={formValues}
											/>
										)
									}}
								/>
							</div>
						) : undefined}

						<div className='grid grid-cols-1 gap-y-3'>
							<FinalReviewerDropdown
								formValues={formValues}
								advisor_reviewer={advisor_reviewer}
							/>
							<ReviewerDropdown
								formValues={formValues}
								advisor_reviewer={advisor_reviewer}
							/>
							<ReceiverEmailDropdown formValues={formValues} />
						</div>

						{documents.length > 0 ? (
							<div>
								<h4 className='text-sm font-semibold'>
									The populated documents are included within this workflow.
								</h4>
								<ul className='m-0 list-disc font-medium'>
									{documents.map((document, idx) => (
										<li key={idx}>{document?.title}</li>
									))}
								</ul>
							</div>
						) : undefined}

						{document_packages.length > 0 ? (
							<div>
								<h4 className='text-sm font-semibold'>
									The following files will be required for upload during the
									workflow fulfillment process.
								</h4>
								<ul className='m-0 list-disc font-medium'>
									{document_packages.map((doc_package, idx) => (
										<li key={idx}>{doc_package?.title}</li>
									))}
								</ul>
							</div>
						) : undefined}

						{upload_files.length > 0 ? (
							<div>
								<ul className='m-0 list-disc'>
									{upload_files.map((file, idx) => (
										<li key={idx}>
											{file?.file} for{' '}
											<span className='font-bold capitalize'>
												{file?.user_type}
											</span>
										</li>
									))}
								</ul>
							</div>
						) : undefined}

						<div>
							<Field
								label='Notes'
								type='select'
								name='notes'
								component={TextareaField}
								placeholder='Add a note if needed about the workflow for the client...'
							/>
						</div>
					</div>

					<div className='flex items-center justify-center space-x-3 !py-5'>
						<Button type='button' size='sm' variant='white' onClick={onClose}>
							Cancel
						</Button>
						<Button type='submit' size='sm' isLoading={formik.isSubmitting}>
							Onboard Now
						</Button>
					</div>

					{/* <div
						className={classNames(
							'flex !py-5',
							selectedIndex > 0 ? 'justify-between' : 'justify-end',
						)}>
						<Button
							type='submit'
							size='sm'
							isLoading={formik.isSubmitting}
							isLoadingText='Assigning...'>
							{selectedIndex === lastStep ? 'Assign Workflow' : 'Next'}
						</Button>
					</div> */}
				</div>
			</form>
		</FormikProvider>
	)
}

export default ClientWorkflowAssignForm
