import { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { SpinLoader } from 'components/Common'
import { useAppRouter } from 'hooks'
import {
	TabIndicator,
	WorkflowAssignForm,
	getAssignInitialValues,
} from 'modules/workflows/workflow-template'
import { WORKFLOW_TEMAPLTE_ROUTES } from 'modules/workflows/workflow-template/routes'
import { useLazyGetOneWorkflowTemplateQuery } from 'modules/workflows/workflow-template/workflow-template-apis'
import { useSelector } from 'react-redux'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateAssign = () => {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const { location, parseQuery, params } = useAppRouter()
	const id = parseQuery?.workflow || params?.id

	const [fetchWorkflow, { data, isUninitialized, isLoading }] =
		useLazyGetOneWorkflowTemplateQuery()
	useEffect(() => {
		if (id) {
			fetchWorkflow(id)
		}
	}, [id])

	const { selectedClients } = useSelector((state) => state.selectedClients)

	const initialValues = useMemo(
		() => getAssignInitialValues(location?.search, data?.workflow, selectedClients),
		[location?.search, data?.workflow],
	)
	const isCreateLink = useMemo(
		() => WORKFLOW_TEMAPLTE_ROUTES.createLink(id) === location.pathname,
		[id, location],
	)

	return (
		<AppLayout
			meta={{
				title: isCreateLink ? 'Create Workflow Link' : 'Assign Workflow',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={isCreateLink ? 'Create Workflow Link' : 'Assign Workflow'}
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						headerRowClassName={classNames('items-center')}>
						<TabIndicator selectedIndex={selectedIndex} />
					</PageHeader>

					<div className='page-body'>
						{(isUninitialized || isLoading) && !initialValues ? (
							<SpinLoader />
						) : (
							<WorkflowAssignForm
								setSelectedIndex={setSelectedIndex}
								selectedIndex={selectedIndex}
								initialValues={initialValues}
							/>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateAssign
