import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { useDataTable } from 'theme/ui/data-table'
import { SearchField } from 'theme/ui/forms'
import TableFiltersCard from 'theme/ui/table-filters-card'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'
import DateRange from './date-range'
import TagsDropdown from './tags-dropdown'

const Filters = ({
	tagList,
	selectedTags,
	handleTagChange,
	applyTags,
	createTag,
	assignWorkflow,
	setTagSearch,
	tagSearch,
	selectedRecords,
	checkedTags,
	isAssignDisabled,
	uniqueTagList,
	previousData,
	setPagination,
}) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 400)
	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	const dateFilterOptions = useMemo(
		() => [
			{ label: 'Today', value: 'today' },
			{ label: 'Yesterday', value: 'yesterday' },
			{ label: 'Last 7 days', value: 'last_7_days' },
			{ label: 'Last 30 days', value: 'last_30_days' },
			{ label: 'Last 6 months', value: 'last_6_months' },
		],
		[],
	)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3'>
				<div className='flex flex-col space-y-3'>
					{activeFilters.length > 0 ? (
						<TableFiltersCard
							filters={activeFilters}
							formik={formik}
							onResetFilters={onResetFilters}
						/>
					) : undefined}

					<div className='flex justify-between'>
						<div className='flex items-center gap-3'>
							<div className='min-w-[320px]'>
								<Field
									className='form-control'
									type='search'
									name='search'
									component={SearchField}
									placeholder='Search clients...'
								/>
							</div>
						</div>
						<div className='flex items-center space-x-3'>
							{checkedTags?.length > 0 &&
								isAssignDisabled &&
								selectedRecords?.length > 0 && (
									<button
										type='button'
										className='btn btn-primary !shadow-sm'
										onClick={assignWorkflow}>
										Assign Workflow
									</button>
								)}
							<TagsDropdown
								setTagSearch={setTagSearch}
								tagSearch={tagSearch}
								tagList={tagList}
								selectedTags={selectedTags}
								handleTagChange={handleTagChange}
								applyTags={applyTags}
								assignWorkflow={assignWorkflow}
								selectedRecords={selectedRecords}
								createTag={createTag}
								checkedTags={checkedTags}
								isAssignDisabled={isAssignDisabled}
								uniqueTagList={uniqueTagList}
								previousData={previousData}
							/>
							<DateRange name='date_range' options={dateFilterOptions} />
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default Filters
