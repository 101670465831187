import { Field, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { Button, InputField, OtpField, PhoneNumberField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useUpdateClientInvitationMutation } from '../invited-client-api'
import FileUploadCard from './document-upload-form/file-upload-card'

const PersonalInfoForm = ({ client }) => {
	const [updateClient] = useUpdateClientInvitationMutation()
	const [validDocumentId, setValidDocumentId] = useState(null)
	const [governmentDocumentId, setGovernmentDocumentId] = useState(null)
	const [avatarId, setAvatar] = useState(null)

	const formik = useFormik({
		initialValues: {
			first_name: client?.first_name,
			last_name: client?.last_name,
			email: client?.email,
			phone_number: client?.phone_number || '',
			address1: client?.userProfile?.address_1 || '',
			address2: client?.userProfile?.address_2 || '',
			city: client?.userProfile?.city || '',
			province: client?.userProfile?.province || '',
			unit: client?.userProfile?.unit || '',
			country: client?.userProfile?.country || '',
			zip_code: client?.userProfile?.zip_code || '',
			sin_number: client?.sin_number || '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = { ...values }
				if (validDocumentId) {
					Object.assign(payload, {
						valid_document: validDocumentId,
					})
				}
				if (governmentDocumentId) {
					Object.assign(payload, {
						government_document: governmentDocumentId,
					})
				}
				if (avatarId) {
					Object.assign(payload, {
						avatar: avatarId,
					})
				}
				delete payload.email
				const response = await updateClient({ payload, id: client?.id })
				if (response?.data?.status === 200) {
					notification('success', 'Client details updated successfully')
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='mb-4 rounded-lg bg-white'>
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Personal Information</h5>
					</div>

					<div className='grid grid-cols-2 gap-3 p-3'>
						<Field name='first_name' component={InputField} label='First Name' />
						<Field name='last_name' component={InputField} label='Last Name' />
						<Field
							name='phone_number'
							component={PhoneNumberField}
							label='Phone Number'
						/>
						<Field name='email' component={InputField} disabled label='Email' />

						<Field name='address1' component={InputField} label='Address 1' />
						<Field name='address2' component={InputField} label='Addres 2' />
						<Field name='unit' component={InputField} label='Unit' />
						<Field name='city' component={InputField} label='City' />
						<Field name='province' component={InputField} label='Province' />
						<Field name='zip_code' component={InputField} label='Zip Code' />
						<Field name='country' component={InputField} label='Country' />
					</div>

					<div className='grid grid-cols-2 gap-3 p-3'>
						<div className='col-span-2'>
							<Field
								name='sin_number'
								component={OtpField}
								otpLength={9}
								label='SIN'
							/>
						</div>
						<FileUploadCard
							title='Government Document'
							file={client?.government_document}
							onSetFileId={(id) => setGovernmentDocumentId(id)}
						/>
						<FileUploadCard
							title='Valid ID'
							file={client?.valid_document}
							onSetFileId={(id) => setValidDocumentId(id)}
						/>

						<FileUploadCard
							title='Profile Picture'
							file={client?.avatar}
							onSetFileId={(id) => setAvatar(id)}
						/>
					</div>

					<div className='mx-auto p-3 text-center'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							isLoadingText='Updating...'>
							Update
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default PersonalInfoForm
