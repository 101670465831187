import { useGetProfileQuery } from 'modules/authentication/auth-api'

const UserQuery = () => {
	const { data, isLoading, isUninitialized } = useGetProfileQuery()

	return {
		user: data?.data?.user,
		isOnboarded: data?.data?.onBoardStatus,
		isLoading: isUninitialized || isLoading,
	}
}

export default UserQuery
