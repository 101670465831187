import { Field, FieldArray } from 'formik'
import Alert from 'theme/ui/alert'
import { CheckboxField } from 'theme/ui/forms'
import AdvisorDropdown from '../advisor-dropdown'
import ClientDropdown from '../client-dropdown'
import CoApplicantField from '../co-applicant-field'
import WorkflowDropdown from '../workflow-dropdown'

const FirstStep = ({ formValues, isCreateLink }) => {
	const applicants = Array.isArray(formValues?.co_applicants)
		? formValues?.co_applicants.map((applicant) => applicant?.value)
		: []
	const client_applicants = [formValues?.client?.value, ...applicants].filter(
		(user) => typeof user !== 'undefined',
	)

	return (
		<div className='form-container'>
			<div className='form-row'>
				<div className='form-heading'>
					<h3>Workflow Template</h3>
					<p>
						Selected is the workflow which you will assign it to specific client and
						co-applicant.
					</p>
				</div>

				<div className='form-fields-card'>
					<WorkflowDropdown />
					<Alert className='mt-2' variant='warning'>
						<div className='flex items-start space-x-1'>
							<i className='bx bxs-info-circle mr-1 text-base' />
							<p className='m-0 text-[13px]'>
								If you switch the workflow the rest of fields will be updated
								according to the requirement of the workflow.
							</p>
						</div>
					</Alert>
				</div>
			</div>

			<div className='form-row'>
				<div className='form-heading'>
					<h3>Assign Advisor</h3>
					<p>
						Assign an advisor who will be taking care as well making sure workflow is
						filled and managed in aproper way
					</p>
				</div>

				<div className='form-fields-card'>
					<AdvisorDropdown formValues={formValues} />
				</div>
			</div>

			{isCreateLink ? (
				<span></span>
			) : (
				<div className='form-row'>
					<div className='form-heading'>
						<h3>Assign Clients</h3>
						<p>Assign the clients or Groups responsible for completing the workflow.</p>
					</div>

					<div className='form-fields-card space-y-6'>
						<Field
							type='checkbox'
							name='createPublicURL'
							id='createPublicURL'
							component={CheckboxField}
							label='Would you like to generate a public link for this workflow assignment?'
						/>
						<ClientDropdown
							formValues={formValues}
							client_applicants={client_applicants}
							name='client.user'
						/>
						{formValues?.client?.user?.some((item) => item.type === 'household') && (
							<Alert className='mt-2' variant='warning'>
								<div className='flex items-start space-x-1'>
									<i className='bx bxs-info-circle mr-1 text-base' />
									<p className='m-0 text-[13px]'>
										Workflows will be assigned to all clients belonging to the
										selected Group(s) as of today. Changes made to the groups
										after assignment will not affect the workflow assigned to
										the clients.
									</p>
								</div>
							</Alert>
						)}
						{/* <ScreenTypeField name='client.screen_type' label='client' /> */}
					</div>
				</div>
			)}

			{formValues.userCount > 1 && Array.isArray(formValues.co_applicants) ? (
				<div className='form-row'>
					<div className='form-heading'>
						<h3>Assign Co Applicants</h3>
						<p>
							Assign the co applicants the responsibility of completing the designated
							fields alongside the client.
						</p>
					</div>

					<div className='form-fields-card'>
						<FieldArray
							name='co_applicants'
							render={(arrayHelpers) => {
								const isDisabled = applicants.length === formValues.userCount - 1
								return (
									<CoApplicantField
										applicants={applicants}
										arrayHelpers={arrayHelpers}
										isDisabled={isDisabled}
										client_applicants={client_applicants}
										formValues={formValues}
									/>
								)
							}}
						/>
					</div>
				</div>
			) : undefined}
		</div>
	)
}

export default FirstStep
