import { flatten } from 'lodash'
import SignatureTab from './signature-tab'

export const steps = [
	{
		dataElement: 'zoomOverlayButton',
		header: 'Zoom',
		text: 'You can adjust the preview of pdf from here.',
	},
	{
		dataElement: 'toolbarGroup-Forms',
		header: 'Signature field',
		text: 'You can add signature field by enable the form tool.',
	},
	{
		dataElement: 'save-button',
		header: 'Save document',
		text: 'After adding signature field you have to save the document by clicking on save button.',
	},
]

export const endFormFieldCreationMode = (formFieldCreationManager) => {
	return new Promise((resolve) => {
		// formFieldCreationManager.applyFormFields()
		// if (formFieldCreationManager.isInFormFieldCreationMode()) {
		// }
		formFieldCreationManager.endFormFieldCreationMode()
		resolve()
	})
}

export const signatureViewDisabledElement = [
	'menuButton',
	'leftPanelButton',
	'viewControlsButton',
	'panToolButton',
	'selectToolButton',
	'toolbarGroup-Shapes',
	'toolbarGroup-Edit',
	'toolbarGroup-Insert',
	'toolbarGroup-Annotate',
	'toolbarGroup-View',
	'toolbarGroup-FillAndSign',
	'toolbarGroup-EditText',
	'searchButton',
	'toggleNotesButton',
	'highlightToolGroupButton',
	'underlineToolGroupButton',
	'strikeoutToolGroupButton',
	'squigglyToolGroupButton',
	'stickyToolGroupButton',
	'markInsertTextGroupButton',
	'markReplaceTextGroupButton',
	'shapeToolGroupButton',
	'freeHandToolGroupButton',
	'freeHandHighlightToolGroupButton',
	'freeTextToolGroupButton',
	'eraserToolButton',
	'checkBoxFieldToolGroupButton',
	'radioButtonFieldToolGroupButton',
	'listBoxFieldToolGroupButton',
	'comboBoxFieldToolGroupButton',
	'textFieldToolGroupButton',
	// "toolsOverlay",
	// "signatureFieldToolButton",
	// "signatureFieldToolButton2",
	// "signatureFieldToolButton3",
	// "signatureFieldToolButton4",
	'applyFormFieldButton',
]

const saveDocument = async (documentViewer, annotationManager) => {
	try {
		const doc = documentViewer.getDocument()
		const xfdfString = await annotationManager.exportAnnotations()
		const data = await doc.getFileData({ xfdfString })
		const arr = new Uint8Array(data)
		const blob = new Blob([arr], { type: 'application/pdf' })
		// formik.setFieldValue('documentBlob', blob)
		// formik.setFieldValue('status', 1)
		return blob
	} catch (error) {
		return error
	}
}

export const savePdfDocument = async (instance) => {
	const { documentViewer, annotationManager } = instance.Core
	const fieldManager = annotationManager.getFieldManager()
	const formFieldCreationManager = annotationManager.getFormFieldCreationManager()
	const signatureFields = []
	fieldManager.forEachField((field) => {
		if (field?.name === 'signature') {
			signatureFields.push(field.children)
		}
	})
	const isSigntureFieldPresent = flatten(signatureFields).length > 0
	if (isSigntureFieldPresent) {
		await endFormFieldCreationMode(formFieldCreationManager)
		return await saveDocument(documentViewer, annotationManager)
	} else {
		throw new Error('Please add at least one signature field to the PDF.')
	}
}

export default SignatureTab
