import classNames from 'classnames'
import { FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { useAppRouter } from 'hooks'
import { routes } from 'modules/workflows'
import { useHistory } from 'react-router-dom'
import { dispatch } from 'store'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import {
	assignValidationSchema,
	assignWorkflow,
	getWorkflowAssignPayload,
	getWorkflowLink,
	validateWorkflow,
} from '.'
import * as actions from '../../../../store/clients/actions'
import PublicLinkPopup from '../public-link-popup'
import { WORKFLOW_TEMAPLTE_ROUTES } from '../routes'
import WorkflowQuery from '../workflow-query'
import { useCreateWorkflowLinkMutation } from '../workflow-template-apis'
import { default as Steps } from './steps/steps'

const WorkflowAssignForm = ({ selectedIndex, setSelectedIndex, initialValues }) => {
	const { parseQuery, navigate, location, params } = useAppRouter()
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false)
	const [link, setLink] = useState(null)
	const [copied, setCopied] = useState(false)
	const onPopupClose = () => history.goBack()
	const onPopupOpen = () => setIsOpen(true)
	const [createWorkflowLink] = useCreateWorkflowLinkMutation()
	const isCreateLink = useMemo(
		() => WORKFLOW_TEMAPLTE_ROUTES.createLink(params?.id) === location.pathname,
		[params, location],
	)
	const households = useMemo(
		() => (typeof parseQuery?.households === 'string' ? parseQuery?.households.split(',') : []),
		[parseQuery?.households],
	)

	const lastStep = 1
	const isLastStep = selectedIndex === lastStep
	const onPrevious = () => setSelectedIndex(selectedIndex - 1)

	const formik = useFormik({
		initialValues: {
			workflow: initialValues?.workflow || null,
			advisor: null,
			client: {
				user: initialValues?.client || null,
				screen_type: 'same_page',
				signPriority: 1,
			},
			co_applicants: [],
			reviewers: [],
			receiver_emails: [],
			clientGroups: households,
			final_reviewer: null,
			userCount: 1,
			isCreateLink: isCreateLink,
			createPublicURL: false,
		},
		enableReinitialize: true,
		validationSchema: assignValidationSchema,
		onSubmit: async (values, { setTouched, setSubmitting }) => {
			try {
				if (isLastStep) {
					setSubmitting(true)
					setLink(null)
					const payload = getWorkflowAssignPayload(values)
					if (isCreateLink) {
						const response = await createWorkflowLink(payload)
						if (response?.data?.status === 200) {
							onPopupOpen()
							notification('success', response?.data?.message)
							const workflowLink = getWorkflowLink(response?.data?.data?.url)
							setLink(workflowLink)
							setCopied(false)
							dispatch(actions.selectedClients([]))
						}
					} else {
						await validateWorkflow(payload?.workflowTemplate?.id)
						await assignWorkflow(payload)
						navigate(routes.templatesListing())
						dispatch(actions.selectedClients([]))
					}
					setSubmitting(false)
				} else {
					setSelectedIndex(selectedIndex + 1)
					setTouched({})
					setSubmitting(false)
				}
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const { user_count, steps } = WorkflowQuery(formValues.workflow?.value)

	useEffect(() => {
		if (user_count > 1) {
			formik.setFieldValue('userCount', user_count)
			formik.setFieldValue(
				'co_applicants',
				Array(user_count - 1).fill({ user: null, screen_type: 'same_page' }),
			)
		} else {
			formik.setFieldValue('userCount', 1)
			formik.setFieldValue('co_applicants', [])
		}
	}, [user_count])

	const documents = useMemo(() => steps?.documents?.raw_documents || [], [steps])
	const document_packages = useMemo(() => steps?.documents?.document_packages || [], [steps])
	const upload_files = useMemo(() => {
		const files = steps?.uploads?.note || '[]'
		return JSON.parse(files)
	}, [steps])

	const advisor_reviewer = useMemo(() => {
		const reviewers = formValues?.reviewers.map((reviewer) => reviewer?.value)
		return [formValues?.advisor?.value, formValues?.final_reviewer?.value, ...reviewers].filter(
			(user) => typeof user !== 'undefined',
		)
	}, [formValues])

	return (
		<Fragment>
			<PublicLinkPopup
				isOpen={isOpen}
				onClose={onPopupClose}
				onOpen={onPopupOpen}
				link={link}
				setCopied={setCopied}
				copied={copied}
			/>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<div className='flex w-full gap-10'>
						<div>
							<Steps
								formValues={formValues}
								formik={formik}
								selectedIndex={selectedIndex}
								documents={documents}
								document_packages={document_packages}
								upload_files={upload_files}
								advisor_reviewer={advisor_reviewer}
								isCreateLink={isCreateLink}
							/>
							<div
								className={classNames(
									'flex !py-5',
									selectedIndex > 0 ? 'justify-between' : 'justify-end',
								)}>
								{selectedIndex > 0 ? (
									<Button
										type='button'
										variant='primary-outline'
										onClick={onPrevious}>
										Previous
									</Button>
								) : undefined}

								<Button
									type='submit'
									isLoading={formik.isSubmitting}
									isLoadingText={isCreateLink ? 'Creating...' : 'Assigning...'}>
									{selectedIndex === lastStep
										? isCreateLink
											? 'Create Link'
											: 'Assign Workflow'
										: 'Next'}
								</Button>
							</div>
						</div>
						<div className='max-w-[200px]'>
							<div className='flex items-start space-x-2 !py-5'>
								<i className='mdi mdi-lightbulb-on text-main' />
								<p className='text-xs text-gray-500'>
									When assigning documents/packages here, do not ignore to fill in
									all the required fields completely and follow the
									document/package adding rules.
								</p>
							</div>
						</div>
					</div>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default WorkflowAssignForm
