import classNames from 'classnames'
import CreateHouseholdModal from 'modules/households/household-create/household-create'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { CLIENT_APP_ROUTES } from '../routes'

const Header = () => {
	const location = useLocation()
	const isInvitePermission = isAllowTo(permissions?.client?.create)
	const isImportPermission = isAllowTo(permissions?.client?.import)
	const isGroupsPermission = isAllowTo(permissions?.groups?.create)
	const [isOpen, setIsOpen] = useState(false)
	const tabs = useMemo(
		() => [
			{
				label: 'Clients',
				href: '/clients',
				permission: isAllowTo(permissions?.client?.list),
			},
			{
				label: 'Invited clients',
				href: '/invited-clients',
				permission: isAllowTo(permissions?.client?.invitedList),
			},
			{
				label: 'Groups',
				href: '/groups',
				permission: isAllowTo(permissions?.groups?.list),
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
					<p className='text-xs text-[#74788D]'>
						The most important feature in the workflow section is the creating one. When
						assigning documents/packages here,
						<br />
						do not ignore to fill in all the required fields completely and follow the
						document/package adding rules.
					</p>
				</div>
				{activeTab?.href == '/groups' ? (
					<div className='flex items-center space-x-2'>
						{isGroupsPermission ? (
							<button
								className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'
								onClick={() => setIsOpen(true)}>
								Create Group
							</button>
						) : undefined}
					</div>
				) : (
					<div className='flex items-center space-x-2'>
						{isInvitePermission ? (
							<Link to={CLIENT_APP_ROUTES.invite()} className='btn btn-primary px-4'>
								Create Client
							</Link>
						) : undefined}

						{isImportPermission ? (
							<Link
								to={CLIENT_APP_ROUTES.import()}
								className='btn !bg-[#50CD89] px-4 text-white'>
								Import CSV
							</Link>
						) : undefined}
					</div>
				)}
			</div>
			<div className='-mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
			<CreateHouseholdModal isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	)
}

export default Header
