import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import ForgetPassword from './auth/forgetpwd/reducer'
// Authentication
import Login from './auth/login/reducer'
import Profile from './auth/profile/reducer'
import Account from './auth/register/reducer'
// Auth0 reducer
import { CommonSlices } from './common/slices'
import { FormBuilderSlices } from './formbuilder/slices'
import { JwtAuthSlices } from './jwtauth/slices'
// Front

import { clientApi } from 'modules/clients/client-apis'
import { workflowFinalReviewApi } from 'modules/workflows/workflow-final-reviews/workflow-final-reviews-apis'
import { workflowTemplateApi } from 'modules/workflows/workflow-template/workflow-template-apis'
import { workflowOutstandingApi } from 'modules/workflows/workflows-outstandings/workflow-outstandings-apis'

import { assignedWorkflowApi } from 'modules/assigned-workflows/assigned-workflow-apis'
import { authApi } from 'modules/authentication/auth-api'
import { documentEventLogApi } from 'modules/document-event-log/document-event-log-api'
import { deletedDocumentApi } from 'modules/documents/deleted-document-api'
import { documentApi } from 'modules/documents/document-api'
import { documentPackageApi } from 'modules/documents/document-package-api'
import { documentVersionApi } from 'modules/documents/document-versions/document-version-api'
import { emailTemplatesApi } from 'modules/email-templates/email-templates-api'
import { fileApi } from 'modules/files/file-apis'
import { noteApi } from 'modules/notes/notes-api'
import { notificationsTemplatesApi } from 'modules/notification-templates/notifications-templates-api'
import { notificationApi } from 'modules/notifications/notification-api'
import { paymentsApi } from 'modules/payments/payments-api'
import { roleApi } from 'modules/roles/roles-api'
import { settingsApi } from 'modules/settings/settings-api'
import { featuresApi } from 'modules/subscriptions/features/features-api'
import { subscriptionApi } from 'modules/subscriptions/subscriptions-api'
import { userSubscriptionApi } from 'modules/subscriptions/user-subscriptions-api'
import { systemFieldApi } from 'modules/system-fields/system-fields-api'
import { systemSettingApi } from 'modules/system-settings/system-settings-api'
import { teamMemberApi } from 'modules/team-members/team-members-api'
import { teamApi } from 'modules/teams/teams-api'
import { usersApi } from 'modules/users/users-api'
import { workflowCommentApi } from 'modules/workflows/workflow-comment-section/workflow-comment-api'
import { userProfileApi } from 'pages/Authentication/profile/components/profile/user-profile-api'

import { invitedClientApi } from 'modules/clients/invited-client-api'
import { householdsApi } from 'modules/households/households-api'
import { onboardingApi } from 'modules/onborading/onborading-api'
import { clientOnboardingApi } from 'pages/Authentication/client-onboarding/clien-onboarding'
import { ClientOnboardingSlices } from './client-onboarding/slices'
import { ClientsSlices } from './clients/slices'
import Layout from './layout/reducer'
import { PermissionSlices } from './permissions/slices'

const rootReducer = combineReducers({
	form,
	jwtauth: JwtAuthSlices.reducer,
	auth: {},
	Permissions: PermissionSlices.reducer,
	common: CommonSlices.reducer,
	formBuilder: FormBuilderSlices.reducer,
	selectedClients: ClientsSlices.reducer,
	clientOnboarding: ClientOnboardingSlices.reducer,
	Layout,
	Login,
	Account,
	ForgetPassword,
	Profile,
	[authApi.reducerPath]: authApi.reducer,
	[assignedWorkflowApi.reducerPath]: assignedWorkflowApi.reducer,
	[clientApi.reducerPath]: clientApi.reducer,
	[clientOnboardingApi.reducerPath]: clientOnboardingApi.reducer,
	[documentApi.reducerPath]: documentApi.reducer,
	[deletedDocumentApi.reducerPath]: deletedDocumentApi.reducer,
	[documentPackageApi.reducerPath]: documentPackageApi.reducer,
	[documentEventLogApi.reducerPath]: documentEventLogApi.reducer,
	[documentVersionApi.reducerPath]: documentVersionApi.reducer,
	[fileApi.reducerPath]: fileApi.reducer,
	[noteApi.reducerPath]: noteApi.reducer,
	[featuresApi.reducerPath]: featuresApi.reducer,
	[invitedClientApi.reducerPath]: invitedClientApi.reducer,
	[onboardingApi.reducerPath]: onboardingApi.reducer,
	[paymentsApi.reducerPath]: paymentsApi.reducer,
	[roleApi.reducerPath]: roleApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[settingsApi.reducerPath]: settingsApi.reducer,
	[systemFieldApi.reducerPath]: systemFieldApi.reducer,
	[subscriptionApi.reducerPath]: subscriptionApi.reducer,
	[systemSettingApi.reducerPath]: systemSettingApi.reducer,
	[teamMemberApi.reducerPath]: teamMemberApi.reducer,
	[teamApi.reducerPath]: teamApi.reducer,
	[userSubscriptionApi.reducerPath]: userSubscriptionApi.reducer,
	[userProfileApi.reducerPath]: userProfileApi.reducer,
	[workflowTemplateApi.reducerPath]: workflowTemplateApi.reducer,
	[workflowOutstandingApi.reducerPath]: workflowOutstandingApi.reducer,
	[workflowFinalReviewApi.reducerPath]: workflowFinalReviewApi.reducer,
	[systemFieldApi.reducerPath]: systemFieldApi.reducer,
	[authApi.reducerPath]: authApi.reducer,
	[subscriptionApi.reducerPath]: subscriptionApi.reducer,
	[paymentsApi.reducerPath]: paymentsApi.reducer,
	[notificationApi.reducerPath]: notificationApi.reducer,
	[featuresApi.reducerPath]: featuresApi.reducer,
	[teamApi.reducerPath]: teamApi.reducer,
	[userSubscriptionApi.reducerPath]: userSubscriptionApi.reducer,
	[systemSettingApi.reducerPath]: systemSettingApi.reducer,
	[workflowCommentApi.reducerPath]: workflowCommentApi.reducer,
	[emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
	[notificationsTemplatesApi.reducerPath]: notificationsTemplatesApi.reducer,
	[householdsApi.reducerPath]: householdsApi.reducer,
})

export default rootReducer
