/* eslint-disable react-hooks/exhaustive-deps */
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { SpinLoader } from 'components/Common'
import DataTablePagination from './data-table-pagination'

const ReactDataTable = ({ columns, data, isLoading, meta, pagination, setPagination }) => {
	const table = useReactTable({
		columns: columns || [],
		data: data || [],
		getCoreRowModel: getCoreRowModel(),
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})

	return (
		<React.Fragment>
			<div className='table-responsive overflow-x-auto'>
					
				<table className='table-1 table-bordered table-hover'>
				<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id} className='px-4 py-2 text-left border whitespace-nowrap'>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody>
						<Fragment>
							{isLoading ? (
								<tr>
									<td colSpan={1000} className="text-center p-4">
										<div>
											<SpinLoader />
										</div>
									</td>
								</tr>
							) : (
								<Fragment>
									{table.getRowModel().rows.length > 0 ? (
										<Fragment>
											{table.getRowModel().rows.map((row) => (
												<tr key={row.id}>
													{row.getVisibleCells().map((cell) => (
														<td key={cell.id} className="px-4 py-3 pr-3 border border-gray-300">
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext(),
															)}
														</td>
													))}
												</tr>
											))}
										</Fragment>
									) : (
										<tr>
											<td colSpan={100}  className='text-center'>
												No record found
											</td>
										</tr>
									)}
								</Fragment>
							)}
						</Fragment>
					</tbody>
				</table>
			</div>

			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</React.Fragment>
	)
}

ReactDataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	pagination: PropTypes.shape({ pageIndex: PropTypes.number, pageSize: PropTypes.number }),
	meta: PropTypes.shape({
		currentPage: PropTypes.number,
		limit: PropTypes.number,
		totalPages: PropTypes.number,
		totalRecords: PropTypes.number,
		hasNextPage: PropTypes.bool,
		hasPrevPage: PropTypes.bool,
		nextPage: PropTypes.number,
		prevPage: PropTypes.number,
	}),
	setPagination: PropTypes.func,
}
ReactDataTable.defaultProps = {
	columns: [],
	data: [],
}

export default ReactDataTable
